import React from 'react';
import { Form, TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives';
import { DictionariesList } from '../../toolympus/components/Dictionaries/DictionariesList';
import { TopicList } from './Dictionaries';

interface Props {
  
}

const HiddenDictionaries = [
  "topics",
  "users",
  "decisionbody",
];

export const AllDictionariesPage = (props: Props) => {
  const tabs = useTabsState([
    ["dictionaries","Справочники"],
    ["topics","Темы"],
  ], "dictionaries", "tab", "__iclrcmp_dicts_tab");

  return (
    <Form>
        <TabsHeader state={tabs} noMargin />
        
        <TabPanel state={tabs.forTab("dictionaries")}>
          <DictionariesList apiPath="/api/settings/dictionaries" hideDictionaries={HiddenDictionaries} />
        </TabPanel>

        <TabPanel state={tabs.forTab("topics")}>
          <TopicList />
        </TabPanel>
    </Form>
  );
}
